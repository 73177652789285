import React from 'react'
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";

//CSS
import '../styles/main.css'
import '../styles/fontawesome-free/css/all.min.css'
import '../styles/sb-admin-2.css'
import 'bootstrap/dist/css/bootstrap.min.css';

//Components
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Dashboard from '../containers/Dashboard';
import Login from '../containers/Login';
import { Provider } from 'react-redux';
import store from '../store/Store';

const Routes = () => {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    {/* <Route exact path="/login" component={Login} /> */}
                    {/* <Route path="/" component={Dashboard} /> */}
                    {/* Para cualquier ruta pública excepto el login restricted = false */}
                    <PublicRoute component={Login} exact path="/login" restricted={true} />
                    <PrivateRoute component={Dashboard} path="/" />
                </Switch>
            </Router>
        </Provider>
    )
}

export default Routes

import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
import useForm from '../../hooks/useForm';
import { getPrintConfig, updatePrintConfig } from '../../services/print-config';
import { configPrinter } from '../../services/printLocal';


const PrintConfig = () => {

    const [dataForm, handleChangeInput, handleChangeForm] = useForm({
        ip_pc: '',
        port_pc: '',
        ip_printer: '',
        port_printer: '',
        printer_name: '',
        method_connection: '',
    })

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        Swal.showLoading()
        getPrintConfig().then((response) => {
            const dataInitial = {
                ip_pc: response.data.ip_pc || '',
                port_pc: response.data.port_pc || '',
                ip_printer: response.data.ip_printer || '',
                port_printer: response.data.port_printer || '',
                printer_name: response.data.printer_name || '',
                method_connection: response.data.method_connection || '',
            }
            handleChangeForm(dataInitial)
            Swal.close();
        })
    }, [refresh])

    const handleSynchronize = async () => {
        Swal.showLoading()
        let url = `http://${dataForm.ip_pc}:${dataForm.port_pc}`
        let dataConfig = {
            printerName: dataForm.printer_name,
            method: dataForm.method_connection,
            host: dataForm.ip_printer,
            port: dataForm.port_printer
        }
        try {
            let response = await configPrinter(url, dataConfig)
            if (response.message) {
                Swal.fire(
                    'Excelente!',
                    'Se ha actualizado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error!',
                    'Hubo un error al actualizar',
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error!',
                'Hubo un error al actualizar',
                'error'
            )
        }
    }

    const handleSubmit = async (e) => {
        Swal.showLoading()
        e.preventDefault()
        try {
            const response = await updatePrintConfig(dataForm);
            if (response) {
                Swal.fire(
                    'Excelente!',
                    'Se ha actualizado exitosamente',
                    'success'
                )
            }
            setRefresh(!refresh)
        } catch (error) {
            Swal.fire(
                'Error!',
                'Hubo un error al actualizar',
                'error'
            )
        }
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Datos de Impresión</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Actualizar información de Impresión</h6>
                </div>
                <Form onSubmit={handleSubmit} >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>IP Computador</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="ip_pc"
                                        value={dataForm.ip_pc}
                                        onChange={handleChangeInput}
                                        required
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Puerto de Pc</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="port_pc"
                                        value={dataForm.port_pc}
                                        onChange={handleChangeInput}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Ip de Impresora</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="ip_printer"
                                        value={dataForm.ip_printer}
                                        onChange={handleChangeInput}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Puerto de Impresora</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="port_printer"
                                        value={dataForm.port_printer}
                                        onChange={handleChangeInput}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Nombre de Impresora</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="printer_name"
                                        value={dataForm.printer_name}
                                        onChange={handleChangeInput}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group >
                                    <Form.Label>Método de Conexión Impresora</Form.Label>
                                    <select className='form-control'
                                        name='method_connection'
                                        value={dataForm.method_connection}
                                        onChange={handleChangeInput}>
                                        <option value="">Seleccione</option>
                                        <option value="NET">NET</option>
                                        <option value="USB">US</option>
                                    </select>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button className="btn btn-success mr-2" type='submit'>Actualizar</button>
                        <button className="btn btn-primary" type='button' onClick={handleSynchronize}>Sincronizar Impresora</button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default PrintConfig

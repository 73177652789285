import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
import useForm from '../../hooks/useForm';
import { getBilling, updateBilling } from '../../services/billing';


const Billing = () => {

    const [dataForm, handleChangeInput, handleChangeForm] = useForm({
        resolution: '',
        consecutive: '',
        prefix: '',
        footer: '',
        header: '',
    })

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        Swal.showLoading()
        getBilling().then((response) => {
            const dataInitial = {
                resolution: response.data.resolution || '',
                consecutive: response.data.consecutive || '',
                prefix: response.data.prefix || '',
                footer: response.data.footer || '',
                header: response.data.header || '',
            }
            handleChangeForm(dataInitial)
            Swal.close();
        })
    }, [refresh])

    const handleSubmit = async (e) => {
        e.preventDefault()
        Swal.showLoading()
        try {
            const response = await updateBilling(dataForm);
            if(response) {
                Swal.fire(
                    'Excelente!',
                    'Se ha actualizado exitosamente',
                    'success'
                )
            }
            setRefresh(!refresh)
        } catch (error) {
            Swal.fire(
                'Error!',
                'Hubo un error al actualizar',
                'error'
            )
        }
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Datos de Facturación</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Actualizar información de Facturación</h6>
                </div>
                <Form onSubmit={handleSubmit} >
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Resolución de Facturación</Form.Label>
                            <Form.Control 
                                type="text"
                                name="resolution"
                                value={dataForm.resolution}
                                onChange={handleChangeInput}
                                required
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Consecutivo Actual</Form.Label>
                            <Form.Control 
                                type="text"
                                name="consecutive"
                                value={dataForm.consecutive}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Prefijo de Facturación</Form.Label>
                            <Form.Control 
                                type="text"
                                name="prefix"
                                value={dataForm.prefix}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Texto Final</Form.Label>
                            <Form.Control 
                                type="text"
                                name="footer"
                                value={dataForm.footer}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Texto Inicial</Form.Label>
                            <Form.Control 
                                type="text"
                                name="header"
                                value={dataForm.header}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <button className="btn btn-success">Actualizar</button>
                </div>
                </Form>
            </div>
        </>
    )
}

export default Billing

import axios from "axios";

export const getTables = async () => {
    try {
        const response =  await axios.get('items/tables?fields=*.*&limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const createTable = async (data) => {
    try {
        const response =  await axios.post(`items/tables`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const updateTable = async (id, data) => {
    try {
        const response =  await axios.patch(`items/tables/${id}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const deleteTable = async (id) => {
    try {
        const response =  await axios.delete(`items/tables/${id}`);
        console.log(response)
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        return error;
    }
}
import axios from "axios";

export const getPrintConfig = async () => {
    try {
        const response =  await axios.get('items/print/1?fields=*.*');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const updatePrintConfig = async (data) => {
    try {
        const response =  await axios.patch(`items/print`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}
import axios from 'axios'

const getUserAuth = async () => {
    let response = false
    try {
        response = await axios.get('users/me')
    } catch (error) {
        response = false
    }

    if (response === false) {
        //Si el token ya expiró intento refrescar el token
        try {
            const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));
            let data = {
                refresh_token: refreshToken,
                mode: "json"
            }
            const newResponse = await fetch(`${process.env.REACT_APP_URL_API}auth/refresh`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const newData = await newResponse.json();
            if (newData) {
                localStorage.setItem('token', JSON.stringify(newData.data.access_token))
                localStorage.setItem('refresh_token', JSON.stringify(newData.data.refresh_token))
            }
        } catch (error) {
            response = false;
            localStorage.clear();
            window.location.href = '/login';
        }
        //Intento loguear con nuevo token
        try {
            response = await axios.get('users/me')
        } catch (error) {
            response = false;
            localStorage.clear();
            window.location.href = '/login';
        }
    }
    return response;
}

export default getUserAuth

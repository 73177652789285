import React, { useContext, useState } from 'react'
import { AppContext } from '../../containers/Dashboard';
import ModalCustom from './ModalCustom';

const ModalActions = ({ product, index }) => {
    const { currentOrder, setCurrentOrder } = useContext(AppContext);
    const [showCustomModal, setShowCustomModal] = useState(false);

    const handleChangeInput = ({ target }) => {
        let dummieOrder = [...currentOrder];
        dummieOrder[index].quantity = Number(target.value);
        setCurrentOrder(dummieOrder);
    }

    const handleButton = (operation) => {
        let dummieOrder = [...currentOrder];
        switch (operation) {
            case 'minus':
                dummieOrder[index].quantity = dummieOrder[index].quantity - 1;
                break;

            case 'plus':
                dummieOrder[index].quantity = dummieOrder[index].quantity + 1;
                break;

            case 'delete':
                dummieOrder.splice(index, 1)
                break;

            case 'custom':
                setShowCustomModal(true);
                break;

            default:
                break;
        }
        setCurrentOrder(dummieOrder);
    }

    return (
        <>
            <ModalCustom
                setShowModal={setShowCustomModal}
                showModal={showCustomModal}
                product={product}
                index={index}
            />
            <button type="button" className="btn btn-warning btn-sm mr-2" onClick={() => { handleButton('custom') }}><i className="fas fa-edit"></i></button>
            <button type="button" className="btn btn-info btn-sm" onClick={() => { handleButton('minus') }}><i className="fas fa-minus"></i></button>
            <input type="number" className="form-control modal__input" value={product.quantity} onChange={handleChangeInput} />
            <button type="button" className="btn btn-info btn-sm" onClick={() => { handleButton('plus') }}><i className="fas fa-plus"></i></button>
            <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => { handleButton('delete') }}><i className="fas fa-trash-alt"></i></button>
        </>
    )
}

export default ModalActions
import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import '../styles/table.css'

const DataTables = ({data, columns}) => {

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
          Viendo { from } a { to } de { size } resultados
        </span>
    );

    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'Primera',
        prePageText: 'Atrás',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'Primera página',
        prePageTitle: 'Ant pág.',
        firstPageTitle: 'Sig pág.',
        lastPageTitle: 'Última página',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: '5', value: 5
            },
            {
                text: '10', value: 10
            },
            {
                text: '20', value: 20
            },
            {
                text: 'Todos', value: data.length
            }
        ] // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
        <ToolkitProvider 
            bootstrap4 
            keyField="id" 
            data={ data }
            columns={ columns }
            search
        >
            {
                props => 
                <>
                <SearchBar 
                    { ...props.searchProps }
                    placeholder="Buscar algo"
                />
                <BootstrapTable
                    { ...props.baseProps }
                    pagination={ paginationFactory(options) }
                    cellSpacing="0"
                    wrapperClasses="table-responsive"
                    classes="table-striped table-hover"
                />
                </>
            }
            
        </ToolkitProvider>
    )
}

export default DataTables

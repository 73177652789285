import axios from "axios";

export const getProducts = async () => {
    try {
        const response =  await axios.get('items/products?limit=-1&fields=*.*');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const updateProducts = async (productId, data) => {
    try {
        const response =  await axios.patch(`items/products/${productId}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const deleteProduct = async (productId) => {
    try {
        const response =  await axios.delete(`items/products/${productId}`);
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        return error;
    }
}

export const createProduct = async (data) => {
    try {
        const response =  await axios.post(`items/products`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export const updateQuantityProducts = async (data) => {
    try {
        const response =  await axios.post(`products/processQuantity`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}
import React, { useContext } from 'react';

import imageProduct from '../../assets/images/product.png';
import { AppContext } from '../../containers/Dashboard';


const Product = ({ product, openModal }) => {

    const { currentOrder, setCurrentOrder } = useContext(AppContext);

    const handleAdd = () => {
        let newItem = {
            product: product,
            quantity: 1,
            aditions: [],
            comment: ''
        }
        setCurrentOrder([
            ...currentOrder,
            newItem
        ])
        openModal(product);
    }

    return (
        <div className='product' onClick={handleAdd}>
            <img
                src={product.image ? product.image : imageProduct}
                alt={product.name}
                className="product__image"
            />
            <span className='product__name'>{product.name}</span>
        </div>
    )
}

export default Product
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { getRooms } from '../../services/rooms'
import { getOrders } from '../../services/order'

import './style.css';
import { numberToMoney } from '../../utils/locations';
import ModalRegister from './ModalRegister';

const Register = () => {

    const [rooms, setRooms] = useState([]);
    const [orders, setOrders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState({})
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        Swal.showLoading();
        getRooms().then(response => {
            console.log(response.data)
            setRooms(response.data)
            Swal.close();
        })
        getOrders().then(response => {
            console.log(response.data)
            setOrders(response.data)
        })
    }, [refresh])

    const handleShowOrder = (order, index) => {
        setDataModal(order);
        setShowModal(true);
    }


    return (
        <>
            <ModalRegister
                setShowModal={setShowModal}
                showModal={showModal}
                dataModal={dataModal}
                refresh={refresh}
                setRefresh={setRefresh}
                setDataModal={setDataModal}
                isEdit
            />
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Caja</h6>
                </div>
                <div className="card-body">
                    {
                        rooms && orders && (
                            rooms.map((room, index) => {
                                let isAtOrder = orders.some(e => e.table?.room.id === room.id);
                                if (isAtOrder) {
                                    return (
                                        <div className="card mb-2 border-card" key={index}>
                                            <div className="card-header background-blue">
                                                {room.name}
                                            </div>
                                            <div className="list-group list-group-flush register__container">
                                                {
                                                    orders.map((order, indexOrder) => {
                                                        if (order.table?.room.id === room.id) {
                                                            return (
                                                                <div key={indexOrder} className="register__table" onClick={() => { handleShowOrder(order, indexOrder) }}>
                                                                    <span>{order.table.name}</span>
                                                                    <span className="register__table__money">{numberToMoney(order.total)}</span>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Register
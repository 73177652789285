import axios from "axios";

export const getUsers = async () => {
    try {
        const response =  await axios.get('users?fields=*.*&limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const getRoles = async () => {
    try {
        const response =  await axios.get('roles');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const deleteUser = async (idUser) => {
    try {
        const response =  await axios.delete(`users/${idUser}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        return false;
    }
}

export const createUser = async (data) => {
    try {
        const response =  await axios.post(`users`, data);
        if (response.status === 200) {
            return true
        }
    } catch (error) {
        return false;
    }
}

export const updateUser = async (userId, data) => {
    try {
        const response =  await axios.patch(`users/${userId}`, data);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        return false;
    }
}
import axios from "axios";

export const getCustomers = async () => {
    try {
        const response =  await axios.get('items/customers?fields=*.*&sort=fullname&limit=-1');
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return [];
    }
}

export const updateCustomers = async (id, data) => {
    try {
        const response =  await axios.patch(`items/customers/${id}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return false;
    }
}

export const deleteCustomer = async (id) => {
    try {
        const response =  await axios.delete(`items/customers/${id}`);
        console.log(response)
        if (response.status === 204) {
            return true;
        }
    } catch (error) {
        return false;
    }
}

export const createCustomer = async (data) => {
    try {
        const response =  await axios.post(`items/customers`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return false;
    }
}
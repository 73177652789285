import React, { useEffect, useState } from 'react'
import { Button, FormControl, Image, InputGroup } from 'react-bootstrap';
import useForm from '../../hooks/useForm';
import { createAdition, deleteAdition, getAditions, updateAdition } from '../../services/aditions'
import DataTables from '../DataTables'
import imageProduct from '../../assets/images/adition.png';
import Swal from 'sweetalert2';
import { uploadFile } from '../../services/media';

const Aditions = () => {

    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [fileSend, setFileSend] = useState();

    const [dataForm, handleChangeInput, handleChangeForm, reset] = useForm({
        name: '',
    });

    useEffect(() => {
        getAditions().then((response) => {
            if (response) {
                setData(response.data)
            }
        })
    }, [refresh])

    const columns = [
        {
            dataField: 'name',
            text: 'Adición',
            sort: true
        },
        {
            dataField: 'image',
            text: 'Imagen',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Image fluid thumbnail src={row?.image ? row.image : imageProduct} alt={row.name} className="modal__product__image" />
                    </>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                    <>
                        <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => { handleEdit(row) }}>
                            <i className="fas fa-pencil-alt"></i>
                        </span>
                        <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => { handleDelete(row) }}>
                            <i className="fas fa-trash"></i>
                        </span>
                    </>
                );
            }
        }
    ]

    const handleEdit = (adition) => {
        setIsEdit(true);
        handleChangeForm(adition)
    }

    const handleDelete = (adition) => {
        Swal.fire({
            title: `Está seguro de eliminar a ${adition.name} ?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                try {
                    const response = await deleteAdition(adition.id);
                    if (response) {
                        Swal.fire(
                            'Eliminado!',
                            'Eliminado con éxito',
                            'success'
                        )
                        setRefresh(!refresh);
                    }else {
                        Swal.fire(
                            'Error!',
                            'Hubo un error al eliminar',
                            'error'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }
                
            }
        })
    }

    const handleSendFile = async () => {
        if (fileSend) {
            const response = await uploadFile(fileSend, 'image');
            dataForm.image = response.secure_url;
            setFileSend(undefined);
            return response.secure_url;
        } else {
            return false;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEdit) {
            if (dataForm.name === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            } else {
                Swal.showLoading();
                try {
                    //Upload file
                    await handleSendFile();
                    //Update Product
                    const response = await updateAdition(dataForm.id, dataForm)
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'Ha sido actualizado exitosamente',
                            'success'
                        )
                        setRefresh(!refresh)
                        setIsEdit(false);
                        reset();
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar',
                        'error'
                    )
                }
            }
        } else {
            if (dataForm.name === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            } else {
                Swal.showLoading();
                try {
                    //Upload file
                    await handleSendFile();
                    //Create Product
                    const response = await createAdition(dataForm);
                    if (response) {
                        Swal.fire(
                            'Excelente!',
                            'Ha sido creado exitosamente',
                            'success'
                        )
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al guardar',
                        'error'
                    )
                }
            }
        }
    }

    const handleChangeFile = ({ target }) => {
        if (target.files.length) {
            const file = target.files[0];
            setFileSend(file);
        } else {
            setFileSend(undefined);
        }
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Adiciones</h1>
            <div className="row">
                <div className="col-md-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Lista de Adiciones</h6>

                        </div>
                        <div className="card-body">
                            <DataTables data={data} columns={columns} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-4">
                        <form onSubmit={handleSubmit}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">{!isEdit ? 'Nueva Adición' : 'Editar Adición'}</h6>
                            </div>
                            <div className="card-body">
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="name">Adición</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl aria-label="name" aria-describedby="name"
                                        name="name"
                                        value={dataForm.name}
                                        onChange={handleChangeInput}
                                        placeholder="Nombre de la adición"
                                        required
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="image">Imagen</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <input type="file" onChange={handleChangeFile} className='form-control' />
                                </InputGroup>
                            </div>
                            <div className="card-footer d-flex flex-row-reverse">
                                <Button variant="success" type="submit">
                                    Guardar
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Aditions